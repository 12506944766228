.action-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
    min-height: 100%;
}
.action-card:hover {
    border-color: #ff6f23;
    background-color: #ff6f23;
    cursor: pointer;
}
.action-card .header-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    top: -30px;
    position: relative;
}
.action-card .header-icon-container .header-button {
    width: 60px;
    height: 60px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 8px;
}
.action-card .header-icon-container .header-button .icon {
    position: relative;
    color: #ff6f23;
    top: 8px;
}
.action-card .card-body {
    padding: 0 1.5em 1.5em 1.5em;
}
.action-card .card-body .card-title {
    font-size: 1.2em;
    margin-bottom: 8px;
    font-weight: bold;
    color: black;
}
.action-card:hover .card-body .card-title,
.action-card:hover .card-body .card-text {
    color:white;
}
.action-card .card-body .card-text {
    font-size: 1em;
    color: #b1bbcd;
}
.action-card .card-body .action-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.action-card .card-body .action-button-container .action-button {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 12px;
    background-color: #ff6f23;
}
.action-card:hover .card-body .action-button-container .action-button {
    background-color: white;
    
}
.action-card .card-body .action-button .icon {
    font-size: 12px;
    position: relative;
    top: 4px;
    left: 1px;
    color: white;
}
.action-card:hover .card-body .action-button .icon {
    color: #ff6f23;
}