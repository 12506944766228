.react-tagsinput {
    display: block;
    width: 100%;
    padding: 0.3125rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--falcon-input-color);
    background-color: var(--falcon-input-bg);
    background-clip: padding-box;
    border: 1px solid var(--falcon-input-border-color);
    appearance: none;
    border-radius: 0.25rem;
    box-shadow: var(--falcon-box-shadow-inset);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin: 0;
}
.react-tagsinput-input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: var(--falcon-input-color);
}
.react-tagsinput-input::placeholder {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.react-tagsinput-tag {
    border: 0;
    background-color: rgb(227, 230, 234);
    border-bottom-left-radius: 800px;
    border-bottom-right-radius: 800px;
    border-collapse: collapse;
    border-top-left-radius: 800px;
    border-top-right-radius: 800px;
    box-sizing: border-box;
    caption-side: bottom;
    color: rgb(125, 137, 155);
    display: inline-block;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 10px;
    font-weight: 600;
    height: 17.0938px;
    line-height: 10px;
    margin-right: 8px;
    padding-bottom: 3.55555px;
    padding-left: 7.11111px;
    padding-right: 7.11111px;
    padding-top: 3.55555px;
    text-align: center;
    text-indent: 0px;
    text-wrap: nowrap;
    vertical-align: baseline;
    white-space-collapse: collapse;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.react-tagsinput-remove,
.react-tagsinput-remove:active,
.react-tagsinput-remove:hover {
    color: rgb(125, 137, 155);
    text-decoration: none;
}
